
import {Component, Vue} from "vue-property-decorator";
import {Util, getPageAPIs, getExcelJSONDataByList} from '@/common/util';
import StoreOperateDataAnalysis from "@/common/api/operationManage/storeOperateDataAnalysis";
import { excuteDownloadExport2Excel } from "@/common/xlsx";

export const pageApiList = getPageAPIs(StoreOperateDataAnalysis)

@Component({})
export default class MerchantsAudit extends Vue {
  private StoreOperateDataAnalysis: any;
  private time:any[] = []
  private params: any = {
    id: '',
    shop_name: '',
    operate_id: '',
    phone: '',
    shop_mode: null,
    turnover:'',
    start_date: '',
    end_date: '',
  };


  constructor() {
    super();
    this.StoreOperateDataAnalysis = new StoreOperateDataAnalysis();
  }
  private created(): void {
    this.search()
    this.refreshOperationerList();
    this.typeObject = Object.fromEntries(
        this.typeArray.map(item => [item.value, item.label])
    );
    // for(const item of this.typeObject){
    //   this.typeObject[item.value] = item.label
    // }
  }

  private allOperationerList: any[] = [];
  private typeArray = [
    {value:1,label:'熊猫球社'},
    {value:2,label:'国粹馆'},
    {value:3,label:'众享台球'},
  ]
  private typeObject:any = {}
  private refreshOperationerList() {
    this.StoreOperateDataAnalysis.getOperationerList(
        {},
        (res: any) => {
          const { list } = res.data || {};

          if (Array.isArray(list)) {
            this.allOperationerList = list;
          } else {
            this.allOperationerList = [];
          }
        },
        (err: any) => {}
    );
  }
  private tableData: any[] = [];
  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };


  private getTable(): void {
    if(this.time && this.time.length){
      const [start_date,end_date] = this.time
      this.params.start_date = start_date
      this.params.end_date = end_date
    }else{
      this.params.start_date = ''
      this.params.end_date = ''
    }
    this.StoreOperateDataAnalysis.getList(
        {
          ...this.params,
          page: this.tablePage.pageNo,
          limit: this.tablePage.pageSize,
        },
        (res: any) => {
          this.tableData = res.data.list;
          this.tablePage.totalCount = res.data.count;
        }
    );
  }

  private restForm(): void {
    this.time = []
    this.params = new Util().clearObject(this.params);
    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getTable();
  }

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }
  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 商户字段名 文案对应关系
  private monthIncomeExcelList2ch: any = {
    shop_id: "店铺ID",
    shop_mode: "店铺类型",
    shop_name: "店铺名称",
    merchant_mobile: "商户手机号",
    total_money: "营业额",
    desk_num: "球桌数量",
    average_total_money: "球桌平均营业额",
    user_name: "运营",
  };
  // 商户Excel 表头的先后顺序
  private monthIncomeExcelSortList: string[] = [
    "shop_id",
    "shop_mode",
    "shop_name",
    "merchant_mobile",
    "total_money",
    "desk_num",
    "average_total_money",
    "user_name",
  ];
  // 点击导出excel 表格
  private handleExportExcelMonthIncomeAuditList() {
    this.getTable();
    const params: any = this.getMonthIncomeAuditTableListParams("exportExcel");

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.StoreOperateDataAnalysis.export(
        params,
        (res: any) => {
          const { start_date, end_date } = params || {};

          const filename = `店铺经营数据分析列表 ${start_date}到${end_date}.xlsx`;
          const { data } = res || {};

          const { list: originList } = data || {};

          const newList = Array.isArray(originList)
              ? originList.map((item) => {
                const newItem = {
                  ...(item || {}),
                };
                newItem.shop_mode = this.typeObject[newItem.shop_mode]
                return newItem;
              })
              : [];

          if (newList.length > 0) {
            const monthIncomeExcelSortList = this.monthIncomeExcelSortList;

            const monthIncomeExcelList2ch = this.monthIncomeExcelList2ch;

            const excelJSONData = getExcelJSONDataByList(
                newList,
                monthIncomeExcelSortList,
                monthIncomeExcelList2ch
            );

            excuteDownloadExport2Excel(excelJSONData, filename);
          } else {
            this.$message.info("选中时段没有可导出的信息");
          }


          this.downloadExcelLoading = false;
        },
        () => {
          this.downloadExcelLoading = false;
        }
    );
  }
  private getMonthIncomeAuditTableListParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.params;

    const tablePage = this.tablePage;

    const time = this.time;


    const { pageNo, pageSize } = tablePage || {};

    const outerParams: any = {};

    if (Array.isArray(time) && time.length === 2) {
      const [start_date, end_date] = time;

      outerParams.start_date = start_date;

      outerParams.end_date = end_date;
    }

    if (
        mode == "exportExcel" &&
        !(outerParams.start_date && outerParams.end_date)
    ) {
      this.$message.error("请选择创建时段导出数据");
      return null;
    }

    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;
    } else if (mode == "exportExcel") {
      // outerParams.is_export = 1
    }


    return outerParams;
  }
}
