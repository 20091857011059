import { BasicsClass } from '@/common/BasicsClass';

export default class StoreOperateDataAnalysis extends BasicsClass {

    public apiUrls: any = new Map([
        ['list', {
            url: '/admin/shop/analysis',
            name: 'list',
            label: '店铺经营数据分析'
        }],
        ['export', {
            url: '/admin/shop/exportAnalysis',
            name: 'export',
            label: '导出店铺经营数据分析'
        }],
        ['getOperationerList', {
            url: '/admin/praise/userAll',
            name: 'operationer-list',
            label: '获取运营人员列表'
        }],
    ])
    public getList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {

        params = {
            ...(params || {})
        }

        const apiUrls = this.apiUrls

        const url = apiUrls.get('list').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }


    public export(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('export').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }


    // 获取运营人员列表
    public getOperationerList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getOperationerList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

}
